import React, { useState, useRef, useEffect } from "react";
import Icon from "./Icon";
import { Link } from "react-router-dom";

import { useLocation } from "react-router-dom";

const LINKS = [
  { link: "/", label: "Abonnement" },
  { link: "/contact-us", label: "Joignez-nous" },
  { link: "/about-us", label: "À propos de nous" },
  { link: "/arbres", label: "Arbres" },
];

const Navbar = (props) => {
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const doChange = (event) => {
    setIsOpen(event.target.checked);
  };

  return (
    <nav className="bg-slate-600 p-4 sticky top-0 z-50">
      <div>
        <div className="container mx-auto flex justify-between items-center">
          <div>
            <Link to="/">
              <Icon />
            </Link>
          </div>
          <div>
            <div className="hidden sm:block">
              <ul className="menu-link-container flex space-x-4 ">
                {LINKS.map((link) => (
                  <li key={link.label}>
                    <Link
                      to={link.link}
                      className={`text-white hover:text-gray-300 ${
                        location.pathname === link.link ? "text-gray-300" : ""
                      }`}
                    >
                      {link.label}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
            <div
              className="flex items-center justify-end sm:hidden w-[150px]"
              ref={ref}
            >
              <div id="menuToggle">
                <input type="checkbox" onChange={doChange} checked={isOpen} />
                <span></span>
                <span></span>
                <span></span>
                <ul id="menu" className="bg-slate-600">
                  {LINKS.map((link) => (
                    <li key={link.label}>
                      <Link
                        to={link.link}
                        className={`text-white hover:text-gray-300 ${
                          location.pathname === link.link ? "text-gray-300" : ""
                        }`}
                        onClick={() => setIsOpen(false)}
                      >
                        {link.label}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
