import React from "react";
import { Routes, Route } from "react-router-dom";

import Subscribe from "./Subscribe";
import AboutUs from "./AboutUs";
import ContactUs from "./ContactUs";
import PrivacyPolicy from "./PrivacyPolicy";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import Trees from "./Trees";

const Landing = () => {
  return (
    <div className="main-container overflow-hidden">
      <Navbar />
      <div className="main-content">
        <Routes>
          <Route exact path="/about-us" element={<AboutUs />} />
          <Route exact path="/contact-us" element={<ContactUs />} />
          <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route exact path="/arbres" element={<Trees />} />
          <Route exact path="/trees" element={<Trees />} />
          <Route path="/" element={<Subscribe />} />
          {/* <Route exact path="/success/:session_id?" element={<Success />} /> */}
          {/* <Route exact path="/customers" element={<Customers />} />
          <Route exact path="/sendSMS" element={<SendSMS />} />
          <Route exact path="/broadcastSMS" element={<BroadcastSMS />} /> */}
          {/* <Route exact path="/__admin/:adminSection?" element={<Admin />} /> */}
        </Routes>
      </div>
      <Footer />
    </div>
  );
};

export default Landing;
